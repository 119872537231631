<!-- 公司简介 -->
<template>
  <div class="introduction">
    <div class="about_one">
      <div class="all_box">
        <div class="about_one_box">
          <div class="about_one_left">
            <h2>ABOUT Bangmang</h2>
            <div class="about_h3">
              <h3>邦芒人力</h3>
            </div>
            <h4>创建于</h4>
            <h5>2007</h5>
            <div class="henxian"></div>
            <p>坚持专注于企业与人才服务</p>
          </div>
          <div class="about_one_right">

            <aboutText></aboutText>

          </div>
        </div>
      </div>
    </div>
    <div class="about_two">
      <div class="all_box">
        <div class="about_two_body">
          <div class="about_two_left">
            <h3>一站式人力资源服务解决方案</h3>
            <div class="henxian"></div>
            <h4>Craftsmanship allows us</h4>
            <h4>to keep improving</h4>
            <p>匠心 让我们精益求精</p>
          </div>
<!--          <div class="about_two_right">-->
<!--            <div class="about_two_right_one">-->
<!--              <h3>service</h3>-->
<!--              <h4>China</h4>-->
<!--            </div>-->
<!--            <h5>500<span>强</span></h5>-->
<!--            <p>2022年跻身中国服务业企业500强</p>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import aboutText from "@/components/aboutText.vue"
export default {
    components: {
        aboutText
    },
    data() {

    },
}
</script>
<style lang="scss" scoped>
.about_one{
    padding-bottom: 90px;
}
.about_one_right{
    width: 70%;    
}
.about_one_box{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.about_one_left {
  text-align: left;
  h2 {
    font-size: 20px;
    color: #8f8f8f;
    margin: 0;
    text-transform: uppercase;
    margin-bottom: 18px;
  }
  .about_h3{
    margin-bottom: 24px;
  }
  h3 {
    font-size: 35px;
    color: #333333;
    margin: 0;
  }
  h4 {
    font-size: 20px;
    color: #696969;
    margin: 0;
    margin-bottom: 20px;
  }
  h5 {
    font-size: 40px;
    color: #d70035;
    margin: 0;
    margin-bottom: 50px;
  }
  .henxian{
    width: 127px;
    height: 3px;
    background: #c5a97b;
    margin-bottom: 32px;
  }
  p {
    font-size: 18px;
    color: #666666;
    margin: 0;
  }
}

.about_two {
  background: url(../../assets/about_two_bg.jpg) center center no-repeat;
  background-size: 100% 100%;
  padding-top: 90px;
}
.about_two_body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.about_two_right {
  background: #c5150f;
  padding: 35px 45px 85px 50px;
  .about_two_right_one {
    text-transform: uppercase;
    float: right;
    h3 {
      font-size: 24px;
      color: #e1cebb;
      margin: 0;
      margin-bottom: 10px;
      border: 4px solid #e1cebb;
      display: inline-block;
      padding: 8px;
    }
    h4 {
      font-size: 24px;
      color: #e1cebb;
      margin: 0;
    }
  }
  h5 {
    text-align: left;
    clear: both;
    font-size: 80px;
    color: #e1cebb;
    margin: 0;
    span {
      font-size: 26px;
    }
  }
  p {
    text-align: left;
    font-size: 19px;
    color: #e1cebb;
    margin: 0;
  }
}
.about_two_left {
  text-align: left;
  padding-top: 35px;
  .henxian {
    width: 138px;
    height: 4px;
    background: #b91c23;
    margin-bottom: 14px;
  }
  h3 {
    font-size: 38px;
    color: #e2d4bd;
    font-weight: lighter;
    margin: 0;
    margin-bottom: 34px;
  }
  h4 {
    font-size: 14px;
    color: #dedede;
    font-weight: bold;
    margin: 0;
    margin-bottom: 10px;
  }
  p {
    font-size: 22px;
    color: #dedede;
    margin: 0;
    margin-top: 37px;
  }
}
</style>