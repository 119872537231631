<!-- 发展历程 -->
<template>
  <div class="course">
    <div class="all_box">
      <div class="course_box">
        <div class="course_left" id="fzlc_bottom">
          <div class="course_left_list" :style="{ top: topNumber + 'px' }">
            <div class="course_left_item">
              <h3>2023</h3>
              <div class="course_left_p">
                <p>荣获数字赋能促进就业典型企业荣誉(浙江省4家)</p>
                <p>荣获上海市闵行区吴泾镇“中流砥柱奖”</p>
                <p>荣获普陀区2022年度高质量发展领军企业奖</p>
              </div>
            </div>
            <div class="course_left_item">
              <h3>2022</h3>
              <div class="course_left_p">
<!--                <p>跻身中国服务业企业500强</p>-->
                <p>浙江省服务业百强企业</p>
                <p>人力资源服务商百强榜单</p>
                <p>财税顾问服务</p>
              </div>
            </div>
            <div class="course_left_item">
              <h3>2021</h3>
              <div class="course_left_p">
                <p>全国直营公司突破228家</p>
                <p>成立宝威法务、橙意企福福利平台</p>
                <p>搭建人力宝众包平台、邦芒直聘平台</p>
              </div>
            </div>
            <div class="course_left_item">
              <h3>2020</h3>
              <div class="course_left_p">
                <p>加深政府合作 辅佐产业园集群</p>
                <p>成立邦芒招商平台</p>
                <p>与政府共建灵活用工产业园、人才市场等</p>
                <p>承接湖州人力资源产业园、上海智立方招商中心</p>
                <p>荣获助力抗击疫情、服务复工复产优秀企业</p>
              </div>
            </div>
            <div class="course_left_item">
              <h3>2019</h3>
              <div class="course_left_p">
                <p>荣升人力资源优秀服务机构</p>
                <p>成立全国首家民营人力资源产业基金</p>
                <p>承接嘉善、绍兴人力资源产业园</p>
                <p>入围上海十强人力资源服务机构</p>
              </div>
            </div>
            <div class="course_left_item">
              <h3>2018</h3>
              <div class="course_left_p">
                <p>北美设立首个海外服务中心</p>
                <p>布局加州、纽约、华盛顿、新泽西</p>
              </div>
            </div>
            <div class="course_left_item">
              <h3>2017</h3>
              <div class="course_left_p">
                <p>全国布局32个省市自治区</p>
                <p>业务覆盖全国</p>
                <p>荣获省级著名商标</p>
              </div>
            </div>
            <div class="course_left_item">
              <h3>2015</h3>
              <div class="course_left_p">
                <p>香港邦芒成立</p>
              </div>
            </div>
            <div class="course_left_item">
              <h3>2013</h3>
              <div class="course_left_p">
                <p>全国直营分公司突破70家</p>
                <p>北京、上海、广州、深圳邦芒陆续成立</p>
                <p>荣获全国优秀人力资源服务商</p>
              </div>
            </div>
            <div class="course_left_item">
              <h3>2012</h3>
              <div class="course_left_p">
                <p>布局全国百城</p>
              </div>
            </div>
            <div class="course_left_item">
              <h3>2009</h3>
              <div class="course_left_p">
                <p>业务布局浙江全省</p>
              </div>
            </div>
            <div class="course_left_item">
              <h3>2007</h3>
              <div class="course_left_p">
                <p>邦芒人力正式成立</p>
              </div>
            </div>
          </div>
        </div>
        <div class="course_right">
          <div class="course_right_top">
            <div class="course_right_one">
              <h3>service</h3>
              <h4>china</h4>
            </div>
<!--            <div class="course_right_two">-->
<!--              <h3>500<span>强</span></h3>-->
<!--              <p>2022年跻身中国服务业企业500强</p>-->
<!--            </div>-->
            <div class="course_btn_list">
              <div
                class="course_btn_item"
                :class="course_one"
                @click="courseClick(1)"
              >
                2023-2021
              </div>
              <div
                class="course_btn_item"
                :class="course_two"
                @click="courseClick(2)"
              >
                2020-2016
              </div>
              <div
                class="course_btn_item"
                :class="course_three"
                @click="courseClick(3)"
              >
                2015-2013
              </div>
              <div
                class="course_btn_item"
                :class="course_four"
                @click="courseClick(4)"
              >
                2012-2007
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      course_one: "gaoliang_on",
      course_two: "gaoliang_off",
      course_three: "gaoliang_off",
      course_four: "gaoliang_off",
      topNumber: 0,
    };
  },
  mounted() {
    this.scrollInit();
  },
  methods: {
    courseClick(type) {
      this.course_one = "gaoliang_off";
      this.course_two = "gaoliang_off";
      this.course_three = "gaoliang_off";
      this.course_four = "gaoliang_off";
      if (type == 1) {
        this.course_one = "gaoliang_on";
        this.topNumber = -60;
      }
      if (type == 2) {
        this.course_two = "gaoliang_on";
        this.topNumber = -750;
      }
      if (type == 3) {
        this.course_three = "gaoliang_on";
        this.topNumber = -1700;
      }
      if (type == 4) {
        this.course_four = "gaoliang_on";
        this.topNumber = -2040;
      }
    },

    scrollInit() {
      let _this = this;
      const nav = document.getElementById("fzlc_bottom");
      var flag; // 鼠标按下
      var downY; // 鼠标点击的Y下标
      var scrollLeft; // 当前元素滚动条的偏移量
      let nowIndex; //当前坐标

      nav.addEventListener("mousedown", function (event) {
        console.log(1);
        flag = true;
        downY = event.clientY; // 获取到点击的x下标
        scrollLeft = this.scrollLeft; // 获取当前元素滚动条的偏移量
        nowIndex = _this.topNumber;
      });
      nav.addEventListener("mousemove", function (event) {
        console.log(2);
        if (flag) {
          // 判断是否是鼠标按下滚动元素区域
          var moveY = event.clientY; // 获取移动的x轴
          var scrollY = moveY - downY; // 当前移动的x轴下标减去刚点击下去的x轴下标得到鼠标滑动距离
          this.scrollLeft = scrollLeft - scrollY; // 鼠标按下的滚动条偏移量减去当前鼠标的滑动距离
          if (scrollY < 0) {
            _this.topNumber = nowIndex - Math.abs(scrollY);
          } else {
            _this.topNumber = nowIndex + Math.abs(scrollY);
          }
          // _this.$forceUpdate();
        }
      });
      // 鼠标抬起停止拖动
      nav.addEventListener("mouseup", function () {
        console.log(3);
        this.topNumber = nowIndex;
        flag = false;
      });
      // 鼠标离开元素停止拖动
      nav.addEventListener("mouseleave", function (event) {
        console.log(4);
        flag = false;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.course_left_list {
  position: absolute;
  left: 0;
  top: 0;
}
.course_left_list:after {
  content: "";
  height: 100%;
  border-left: 2px dashed #e7d8ca;
  position: absolute;
  left: 50px;
  top: 0;
}
.course_left_item:after {
  content: "";
  position: absolute;
  width: 11px;
  height: 11px;
  border-radius: 11px;
  background: #d70035;
  top: 10px;
  left: 46px;
  z-index: 2;
}
.course_left_item {
  text-align: left;
  margin: 65px 0;
  padding-left: 90px;
  position: relative;
  h3 {
    font-size: 28px;
    color: #d70035;
    margin: 0;
    margin-bottom: 15px;
  }
  .course_left_p {
    p {
      font-size: 16px;
      color: #333333;
      line-height: 24px;
    }
  }
}
.gaoliang_on:after {
  content: "";
  width: 200px;
  height: 2px;
  background: #fff;
  position: absolute;
  right: 0;
  top: 16px;
}
.gaoliang_on {
  color: #fff !important;
}
.course_btn_list {
  margin-top: 50px;
  text-align: left;
  padding-left: 40px;
  .course_btn_item {
    cursor: pointer;
    position: relative;
    font-size: 30px;
    color: #e1cebb;
    font-weight: bold;
    margin-bottom: 30px;
  }
}
.course_right_one {
  padding: 20px 22px 0 0;
  margin-bottom: -40px;
}
.course_box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.course_left {
  cursor: grab;
  background: #fff;
  width: 67%;
  height: 466px;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  border-bottom: 16px solid #fff;
  border-top: 16px solid #fff;
}
.course_right {
  background: url(../../assets/course_bg.jpg) center center no-repeat;
  background-size: 100% 100%;
  width: 33%;
}
.course_right_one {
  display: inline-block;
  float: right;
  h3 {
    font-size: 24px;
    color: #e1cebb;
    border: 4px solid #e1cebb;
    margin: 0;
    text-transform: uppercase;
  }
  h4 {
    font-size: 24px;
    color: #e1cebb;
    margin: 0;
    text-transform: uppercase;
  }
}
.course_right_two {
  padding-left: 40px;
  clear: both;
  text-align: left;
  h3 {
    font-size: 80px;
    color: #e1cebb;
    margin: 0;
    span {
      font-size: 26px;
    }
  }
  p {
    font-size: 19px;
    color: #e1cebb;
    margin: 0;
  }
}
</style>