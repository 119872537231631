<!-- 公司荣誉 -->
<template>
  <div class="honor">
    <div class="all_box">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in listData"
            :key="index"
          >
            <div class="slide_box">
              <img :src="item" />
            </div>
          </div>
        </div>
        <!-- 如果需要导航按钮 -->
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper";

// 荣誉图片
import honor01 from "@/assets/honor/honor01.jpg";
import honor02 from "@/assets/honor/honor02.jpg";
import honor03 from "@/assets/honor/honor03.jpg";
import honor04 from "@/assets/honor/honor04.jpg";
import honor05 from "@/assets/honor/honor05.jpg";
import honor06 from "@/assets/honor/honor06.jpg";
import honor07 from "@/assets/honor/honor07.jpg";
import honor08 from "@/assets/honor/honor08.jpg";
import honor09 from "@/assets/honor/honor09.jpg";
import honor10 from "@/assets/honor/honor10.jpg";
import honor11 from "@/assets/honor/honor11.jpg";
import honor12 from "@/assets/honor/honor12.jpg";
import honor13 from "@/assets/honor/honor13.jpg";
import honor14 from "@/assets/honor/honor14.jpg";
import honor15 from "@/assets/honor/honor15.jpg";
import honor16 from "@/assets/honor/honor16.jpg";
import honor17 from "@/assets/honor/honor17.jpg";
import honor18 from "@/assets/honor/honor18.jpg";
import honor19 from "@/assets/honor/honor19.jpg";
import honor20 from "@/assets/honor/honor20.jpg";
import honor21 from "@/assets/honor/honor21.jpg";
import honor22 from "@/assets/honor/honor22.jpg";
import honor23 from "@/assets/honor/honor23.jpg";
import honor24 from "@/assets/honor/honor24.jpg";
import honor25 from "@/assets/honor/honor25.jpg";
import honor26 from "@/assets/honor/honor26.jpg";
import honor27 from "@/assets/honor/honor27.jpg";
import honor28 from "@/assets/honor/honor28.jpg";
import honor29 from "@/assets/honor/honor29.jpg";
import honor30 from "@/assets/honor/honor30.jpg";
import honor31 from "@/assets/honor/honor31.jpg";
import honor32 from "@/assets/honor/honor32.jpg";

export default {
  data() {
    return {
      listData: [
        honor02,
        honor04,
        honor05,
        honor06,
        honor03,
        honor07,
        honor08,
        honor09,
        honor10,
        honor11,
        honor12,
        honor13,
        honor14,
        honor15,
        honor16,
        honor17,
        honor18,
        honor19,
        honor20,
        honor21,
        honor22,
        honor23,
        honor24,
        honor25,
        honor26,
        honor27,
        honor28,
        honor29,
        honor30,
        honor31,
        honor32,
      ],
    };
  },
  mounted() {
    this.handler();
  },
  methods: {
    handler() {
      this.$nextTick(() => {
        var mySwiper = new Swiper(".swiper-container", {
          loop: true,
          slidesPerView: 5,
          // 如果需要前进后退按钮
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        });
        console.log(mySwiper);
      });
    },
  },
};
</script>

<style lang="scss">
.honor {
  padding: 50px 0 80px ;
  .swiper-button-prev {
    background: url(../../assets/left.png) center center no-repeat;
    width: 30px;
    height: 42px;
    background-size: 100% 100%;
  }
  .swiper-button-prev::after{
    display: none;
  }
  .swiper-button-next {
    background: url(../../assets/right.png) center center no-repeat;
    width: 30px;
    height: 42px;
    background-size: 100% 100%;
  }
  .swiper-button-next::after{
    display: none;
  }
  .swiper-button-next {
  }
  .swiper-slide-active {
    transform: scale(0.7) translateX(70px);
    transition: all .2s;
  }
  .swiper-slide-active + div {
    transform: scale(0.8) translateX(10px);
    transition: all .2s;
  }
  .swiper-slide-active + div + div {
    transform: scale(1);
    transition: all .2s;
  }
  .swiper-slide-active + div + div + div {
    transform: scale(0.8) translateX(-10px);
    transition: all .2s;
  }
  .swiper-slide-active + div + div + div + div {
    transform: scale(0.7) translateX(-70px);
    transition: all .2s;
  }
  .swiper-slide-active + div + div + div + div + div {
    transform: scale(0.7) translateX(-30px);
    transition: all .2s;
  }
  .swiper-slide-prev {
    transform: scale(0.7) translateX(-70px);
    transition: all .2s;
  }
}
</style>

<style lang="scss" scoped>
</style>